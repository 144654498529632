import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/open-sans";
import "@fontsource/roboto";
import theme from "./lib/theme";
import { FilesProvider } from "./context/files";

import { GlobalWorkerOptions } from "pdfjs-dist";
import { AuthTokensProvider } from "./context/authToken";
import Loading from "./components/Loading";
import { QueryClient, QueryClientProvider } from "react-query";
import { CouponsProvider } from "./context/coupon";
import { LogoProvider } from "./context/logo";
import { StripeIntentSecretProvider } from "./context/stripeIntentSecret";
import { LoginRedirectProvider } from "./context/loginRedirect";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			suspense: true,
		},
	},
});

GlobalWorkerOptions.workerSrc = window.location.origin + "/pdf.worker.js"; // from https://github.com/mozilla/pdf.js

root.render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<Suspense fallback={<Loading />}>
				<QueryClientProvider client={queryClient}>
					<AuthTokensProvider>
						<FilesProvider>
							<CouponsProvider>
								<LogoProvider>
									<StripeIntentSecretProvider>
										<LoginRedirectProvider>
											<App />
										</LoginRedirectProvider>
									</StripeIntentSecretProvider>
								</LogoProvider>
							</CouponsProvider>
						</FilesProvider>
					</AuthTokensProvider>
				</QueryClientProvider>
			</Suspense>
		</ChakraProvider>
	</React.StrictMode>
);

reportWebVitals();
