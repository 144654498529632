export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const CartPricing: {
	[key: string]: number;
} = {
	A0: 11.95,
	A1: 6.9,
	A2: 4.5,
	A3: 2.9,
};

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
