import * as React from "react";
import { useLocalStorageState } from "../hooks/useLocalStorageState";
import { LogoContextType } from "../@types/logo";

export const logoContext = React.createContext<LogoContextType | null>(null);

export const LogoProvider = ({ children }: { children: React.ReactNode }) => {
	const [logo, setLogo] = useLocalStorageState(null, "logo");

	return (
		<logoContext.Provider value={{ logo, setLogo }}>
			{children}
		</logoContext.Provider>
	);
};
