import * as React from "react";
import {
	IStripeIntentSecret,
	StripeIntentSecretContextType,
} from "../@types/stripeIntentSecret";

export const stripeIntentSecretContext =
	React.createContext<StripeIntentSecretContextType | null>(null);

export const StripeIntentSecretProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [stripeIntentSecret, setStripeIntentSecret] =
		React.useState<IStripeIntentSecret | null>(null);

	return (
		<stripeIntentSecretContext.Provider
			value={{ stripeIntentSecret, setStripeIntentSecret }}
		>
			{children}
		</stripeIntentSecretContext.Provider>
	);
};
