import { Flex } from "@chakra-ui/react";
import DotLoader from "react-spinners/DotLoader";

const Loading = () => {
	return (
		<Flex justifyContent={"center"} alignItems={"center"} height={"80vh"}>
			<DotLoader color="#ED8936" size={75} />
		</Flex>
	);
};

export default Loading;
