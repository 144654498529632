import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { authTokensContext } from "../../context/authToken";
import { SuspenseHOC } from "../SuspenseHOC";
import { AuthTokenContextType } from "../../@types/authToken";

const PrivateRoute = () => {
	const { authToken } = useContext(authTokensContext) as AuthTokenContextType;
	return authToken ? (
		<Outlet />
	) : (
		<Navigate replace to="/ask-login-or-sign-up" />
	);
};

export default SuspenseHOC(PrivateRoute);
