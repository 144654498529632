import * as React from "react";
import { CouponContextType } from "../@types/coupon";
import { useLocalStorageState } from "../hooks/useLocalStorageState";

export const couponsContext = React.createContext<CouponContextType | null>(
	null
);

export const CouponsProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [coupon, setCoupon] = useLocalStorageState(null, "coupon");

	return (
		<couponsContext.Provider value={{ coupon, setCoupon }}>
			{children}
		</couponsContext.Provider>
	);
};
