import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as React from "react";
import PrivateRoute from "./components/routes/PrivateRoute";

const PublicRoute = React.lazy(() => import("./components/routes/PublicRoute"));
const UploadFiles = React.lazy(() => import("./pages/UploadFiles"));
const Cart = React.lazy(() => import("./pages/Cart"));
const AskLoginOrSignUp = React.lazy(() => import("./pages/AskLoginOrSignup"));
const Login = React.lazy(() => import("./pages/Login"));
const SignUp = React.lazy(() => import("./pages/Signup"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const Payment = React.lazy(() => import("./pages/Payment"));
const MyAccount = React.lazy(() => import("./pages/MyAccount"));
const EmailConfirmation = React.lazy(() => import("./pages/EmailConfirmation"));
const ForgetPassword = React.lazy(() => import("./pages/ForgetPassword"));
const ForgetPasswordConfirm = React.lazy(
	() => import("./pages/ForgetPasswordConfirm")
);
const PaymentSuccess = React.lazy(() => import("./pages/PaymentSuccess"));

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route index path="/" element={<UploadFiles />} />
				<Route path="/cart" element={<Cart />} />
				<Route
					path="/ask-login-or-sign-up"
					element={<AskLoginOrSignUp />}
				/>
				<Route path="/login" element={<PublicRoute />}>
					<Route path="/login" element={<Login />} />
				</Route>
				<Route path="/signup" element={<PublicRoute />}>
					<Route path="/signup" element={<SignUp />} />
				</Route>
				<Route path="/checkout" element={<PrivateRoute />}>
					<Route path="/checkout" element={<Checkout />} />
				</Route>
				<Route path="/payment" element={<PrivateRoute />}>
					<Route path="/payment" element={<Payment />} />
				</Route>
				<Route path="/my-account" element={<PrivateRoute />}>
					<Route path="/my-account" element={<MyAccount />} />
				</Route>
				<Route
					path="/email-confirmation/:uid/:token"
					element={<EmailConfirmation />}
				/>
				<Route path="/forget-password" element={<PublicRoute />}>
					<Route
						path="/forget-password"
						element={<ForgetPassword />}
					/>
				</Route>
				<Route
					path="/forget-password-confirm/:uid/:token"
					element={<PublicRoute />}
				>
					<Route
						path="/forget-password-confirm/:uid/:token"
						element={<ForgetPasswordConfirm />}
					/>
				</Route>
				<Route path="/payment-success" element={<PaymentSuccess />} />
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
