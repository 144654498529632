import * as React from "react";
import { useLocalStorageState } from "../hooks/useLocalStorageState";
import { AuthTokenContextType, IUser } from "../@types/authToken";
import { useToast } from "@chakra-ui/react";
import axiosInstance from "../lib/axiosInstance";
import { useQuery } from "react-query";

export const authTokensContext =
	React.createContext<AuthTokenContextType | null>(null);

export const AuthTokensProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [authToken, setAuthToken] = useLocalStorageState(null, "authTokens");
	const [user, setUser] = React.useState<IUser | null>(null);
	const toast = useToast();
	const me = useQuery(
		"me",
		async () =>
			axiosInstance
				.get("authentication/users/me/", {
					headers: {
						Authorization: `Token ${authToken?.auth_token}`,
					},
				})
				.then((res) => res.data),
		{
			enabled: false,
			onSuccess: (data) => setUser(data),
		}
	);
	const fetchMe = () => me.refetch();
	React.useEffect(() => {
		if (authToken) {
			axiosInstance.defaults.headers.common[
				"Authorization"
			] = `Token ${authToken.auth_token}`;
			fetchMe();
		} else {
			delete axiosInstance.defaults.headers.common["Authorization"];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken]);

	const logout = () => {
		setAuthToken(null);
		setUser(null);
		toast({
			title: "Logged out",
			description: "You have been logged out.",
			status: "success",
			duration: 3000,
			isClosable: true,
		});
	};

	return (
		<authTokensContext.Provider
			value={{ authToken, setAuthToken, logout, user, setUser, fetchMe }}
		>
			{children}
		</authTokensContext.Provider>
	);
};
