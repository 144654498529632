import * as React from "react";
import { useLocalStorageState } from "../hooks/useLocalStorageState";
import { IFile, FileContextType } from "../@types/files";

export const filesContext = React.createContext<FileContextType | null>(null);

export const FilesProvider = ({ children }: { children: React.ReactNode }) => {
	const [files, setFiles] = useLocalStorageState([], "files");

	const removeFile = (name: string) => {
		setFiles((files: IFile[]) =>
			files.filter((file: IFile) => file.name !== name)
		);
	};

	const removeAllFiles = () => {
		setFiles([]);
	};

	let getSubTotal: () => number = () =>
		Math.round(
			files.reduce((acc: number, file: IFile) => acc + file.subTotal, 0) *
				100
		) / 100;

	return (
		<filesContext.Provider
			value={{ files, setFiles, removeFile, removeAllFiles, getSubTotal }}
		>
			{children}
		</filesContext.Provider>
	);
};
