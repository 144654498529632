import {
	StyleFunctionProps,
	extendTheme,
	withDefaultColorScheme,
} from "@chakra-ui/react";

export const colors = {
	white: "#ffffff",
};

const fonts = {
	heading: "Open Sans",
	body: "Roboto",
};

const config = {
	initialColorMode: "light",
	useSystemColorMode: false,
};

const styles = {
	global: (props: StyleFunctionProps) => ({
		body: {
			bg: props.colorMode === "dark" ? "gray.900" : "gray.50",
		},
	}),
};

const theme = { colors, fonts, config, styles };

const customTheme = extendTheme(
	theme,
	withDefaultColorScheme({
		colorScheme: "orange",
		components: ["Button", "Badge", "Text"],
	})
);

export default customTheme;
