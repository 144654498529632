import * as React from "react";
import { ILoginRedirect, LoginRedirectContextType } from "../@types/loginRedirect";

export const loginRedirectContext =
	React.createContext<LoginRedirectContextType | null>(null);

export const LoginRedirectProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [loginRedirect, setLoginRedirect] =
		React.useState<ILoginRedirect | null>(null);

	return (
		<loginRedirectContext.Provider
			value={{ loginRedirect, setLoginRedirect }}
		>
			{children}
		</loginRedirectContext.Provider>
	);
};
