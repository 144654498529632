import { Suspense } from "react";
import React from "react";

import Loading from "./Loading";

export function SuspenseHOC(WrappedComponent: React.FC) {
	function ComponentWithSuspense(props: React.ComponentProps<any>) {
		return (
			<Suspense fallback={<Loading />}>
				<WrappedComponent {...props} />
			</Suspense>
		);
	}

	return ComponentWithSuspense;
}
